import React from 'react'

function Footer() {
  return (
    <div> <p className=" me-3 position-fixed bottom-0 end-0 text-light" >
    <span className="fs-5 fw-1 ">Copyright</span> @ 2023 Desi Rummy
  </p></div>
  )
}

export default Footer